<script setup lang="ts">
const route = useRoute()
const isShowTaskMenu = ref(false)

function createMenuItems(id: string) {
  return [
    {
      name: 'Categorias',
      icon: 'hugeicons:edit-02',
      route: `/places/${id}/marketplaces/${route.params.name}`,
      active: true,
      type: '',
    },
    {
      name: 'Avisos',
      icon: 'hugeicons:bitcoin-store',
      route: `/places/${id}/marketplaces/${route.params.name}/banners`,
      type: 'banners',
    },
  ]
}
</script>

<template>
  <NuxtLayout name="places-menu">
    <div class="relative flex h-full gap-5 sm:h-[calc(100vh_-_150px)]">
      <div
        class="panel absolute z-10 hidden h-full w-[240px] max-w-full flex-none space-y-4 p-4 ltr:rounded-r-none rtl:rounded-l-none xl:relative xl:block xl:h-auto ltr:xl:rounded-r-md rtl:xl:rounded-l-md col-span-1"
        :class="{ '!block': isShowTaskMenu }"
      >
        <div class="flex h-full flex-col">
          <div class="space-y-1">
            <NuxtLink
              v-for="(menuItem, _index) in createMenuItems(route.params.id as string)"
              :key="_index"
              :to="menuItem.route"
              class="flex h-10 w-full items-center gap-5 rounded-md p-2 font-medium hover:bg-white-dark/10 hover:text-primary dark:hover:bg-[#181F32] dark:hover:text-primary"
              :class="{ 'bg-gray-100 text-primary dark:bg-[#181F32] dark:text-primary': route.fullPath === menuItem.route }"
            >
              <Icon :name="menuItem.icon" />
              {{ menuItem.name }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <div
        class="overlay absolute z-[5] hidden h-full w-full rounded-md bg-black/50"
        :class="{ '!block xl:!hidden': isShowTaskMenu }"
        @click="isShowTaskMenu = !isShowTaskMenu"
      />
      <div class="panel h-full flex-1 overflow-y-auto p-4">
        <div class="flex items-center ltr:mr-3 rtl:ml-3 pb-4">
          <button
            type="button"
            class="block hover:text-primary ltr:mr-3 rtl:ml-3 xl:hidden"
            @click="isShowTaskMenu = !isShowTaskMenu"
          >
            <Icon name="hugeicons:menu-02" />
          </button>
        </div>
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>
